.woocommerce span.onsale {
    background-color: $color-primary;
    padding: 0 1em;
    top: 0;
    left: 0;
    border-radius: 0;
}

// metadata
.posted_in,
.sku_wrapper,
.tagged_as {
    margin-bottom: 0.5em;
}

.product_meta-label {
    width: 25%;
    display: inline-block;
    font-weight: bold;
}

.woocommerce div.product .product_title {
    margin-bottom: 0;
}

// product page
.woocommerce #content div.product div.summary, .woocommerce div.product div.summary, .woocommerce-page #content div.product div.summary, .woocommerce-page div.product div.summary {
    width: 68% !important;
    display: flex;
    flex-direction: column;
}

.woocommerce #content div.product div.images, .woocommerce div.product div.images, .woocommerce-page #content div.product div.images, .woocommerce-page div.product div.images {
    width: 28% !important;
}

.woocommerce div.product div.images img {
    border: 1px solid $color-gray-light;
}

.yith_ywraq_add_item_response_message,
.yith_ywraq_add_item_response {
    margin-bottom: 0.5em;
}

.yith-ywraq-add-to-quote {
    order: 1;
    margin-top: 1em;
}

// buttons
.woocommerce #respond input#submit, 
.woocommerce button.button, 
.woocommerce input.button,
.yith-ywraq-add-to-quote a.add-request-quote-button,
.yith_ywraq_add_item_browse_message a {
    text-decoration: none;
	background-color: $color-primary !important;
	color: $color-white !important;
	padding: 0.75em 1em !important;
	display: inline-block !important;
	border: 0 !important;
	cursor: pointer !important;
	transition: all .2s ease-in-out;
	text-transform: uppercase !important;
    font-family: $font-heading !important;
    border-radius: 0 !important;
    font-weight: normal !important;

	&:hover,
	&:active,
	&:focus {
		background: $color-primary-dark !important;;
	}
}

// Form request quote
.woocommerce form .form-row textarea {
    height: inherit !important;
}

// Additional info tabel
.woocommerce table.shop_attributes th {
    text-align: left;
    font-family: $font-heading;
    font-size: 1.2em;
}

// Woocommerce Columns 
.woocommerce ul.products {

    @include media-query($breakpoint-m) {
        display: flex;
        flex-wrap: wrap;
        clear: both;
        margin-left: -1em !important;
    }
}

.woocommerce ul.products li.product {
    margin-bottom: 0 !important;
    display: flex;
    // flex-grow: 1;
    margin: 0 !important;
    
    @include media-query($breakpoint-m) {
        padding-left: 1em !important;

    }
}

.woocommerce ul.products.columns-3 li.product, .woocommerce-page ul.products.columns-3 li.product {
    width: 100% !important;

    @include media-query($breakpoint-m) {
        width: percentage(1/2) !important;
    }
    @include media-query($breakpoint-l) {
        width: percentage(1/3) !important;
    }
}

// Header Productpagina
.woocommerce-result-count {
    font-size: 0.9em;
    margin: 8px 0 0 !important;
}

.woocommerce-ordering,
.woocommerce .woocommerce-ordering select {
    max-width: 201px;
    overflow: hidden;
    float: right;
}

.woocommerce .woocommerce-ordering {
    position: relative;
    
    select {
        border: 0;
        font-size: 0.95em;
        color: $color-gray-dark;
        width: 100%;
        padding: 0.3em 1.5em 0.3em 0.5em;
        position: relative;
        cursor: pointer;
        background: white;
        height: 2.25em;
        border: 1px solid $color-gray-light;
        border-radius: 0;
        appearance: none;

        &:active,
        &:hover,
        &:focus {
            outline: 0;
            border-color: $color-gray-dark;
        }

    }
    &:after {
        content: '';
        background: url('../hydraunica/assets/images/arrow-right.svg');
        vertical-align: middle;
        transition: all 0.3s ease-in-out;
        margin-left: 1.25em;
        width: 1em;
        height: 1em;
        transform: rotate(90deg);
        position: absolute;
        background-size: 1em;
        margin-top: 0;
        z-index: 2;
        right: 0.5em;
        top: 0.65em;
        pointer-events: none;
    }
}

// Related 
section.related.products {
    clear: both;
}

// Request a quote page
.product-subtotal {
    display: none;
}

.product-thumbnail {
    max-width: 50px;

    img {
        border: 1px solid $color-gray-light;
    }
}

.product-remove {
    max-width: 10px;
}

.product-name a {
    font-size: 1.25em;
    text-transform: uppercase;
    font-family: $font-heading;
    text-decoration: none;

    &:active,
    &:hover,
    &:focus {
        color: $color-primary;
    }
}

// Filter
.woof_products_top_panel {
    li span {
        background: none !important;
        position: relative;
        &:after {
            content: '';
            background: url('../hydraunica/assets/images/close.png');
            vertical-align: middle;
            transition: all 0.3s ease-in-out;
            margin-left: 1.25em;
            width: 1em;
            height: 1em;
            position: absolute;
            background-size: 1em;
            margin-top: 0;
            z-index: 2;
            right: -0.25em;
            top: 0.15em;
            pointer-events: none;
        }
    }
    a {
        background: #9488B2;
        padding: 0.5em 0.75em 0.75em 0.75em;
        color: $color-white;
    }
}

// sidebar

.cat-item {
    display: flex;
    flex-wrap: wrap;
    a {
        float: left;
    }
    .count {
        padding-left: 0.25em;
    }
}

.product-categories ul:not(.children)>li {
    display: flex;
    line-height: 1.7em;
    border-bottom: 1px solid #ddd;
    margin-top: 0.35em;
    flex-wrap: wrap;
}

.product-categories span.count {
    color: $color-gray-light;
    margin-left: auto;
    font-size: 0.8em;
}

.product-categories ul.children {
    padding: 0.5em 0em 0em 1em;
    border-left: 1px solid #ddd;
    flex: 1 0 100%;
}

.product-categories ul.children span.count {
}

.product-categories li.cat-parent:not(.children)> span:after {
    content: '+';
    font-size: 20px;
    font-weight: 900;
    color: #000;
    padding-left: 0.25em;
    display: inline-block;
    width: 1em;
    text-align: center;
    
}
.product-categories li.cat-parent:not(.children) span.flipped:after {
    content: '-';
}

// ajax be rocket filter

ul.berocket_aapf_widget li > span {
    display: flex !important;
    justify-content: space-between;
}

ul.berocket_aapf_widget li > span > input[type="radio"] + .berocket_label_widgets:before {
    margin-right: 0.75em;
    margin-top: 0.4em;
}

.brw-product_cat-uncategorized-nl,
.brw-product_cat-uncategorized {
    display: none;
}

.berocket_aapf_widget.rightpcs .berocket_label_widgets {
    line-height: 1.5;
}

.berocket_aapf_widget.rightpcs .berocket_label_widgets {
    display: flex !important;
}

span.br_child_toggle {
    margin-top: 0.4em;
}

// order filtering
.woocommerce-page {
    .Grid--woocommerce {
        .u-xl-size1of3 {
            order: 0;
            @include media-query($breakpoint-xl) {
                order: inherit;
            }
        }
        .u-xl-size2of3 {
            order: 1;
            @include media-query($breakpoint-xl) {
                order: inherit;
            }
        }
    }
}