/**
 * Implements the Sticky Footer pattern.
 *
 * On pages with less content the footer will still be positioned at the bottom
 * of the screen.
 *
 * See: http://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
 */

body {
	display: flex;
	flex-direction: column;
	// This needs the `html` tag to be set to `height: 100%`
	min-height: 100%;
}

// Make sure the content area will take up all available space in the
// container, and will be at least the height it needs for its content.
.Site-content {
	flex: 1 0 auto;
	width: 100%;

    &.is-hidden {
        overflow: hidden;
        height: 100vh;
    }
}

.Site-contentNav {
	display: flex;
    align-items: flex-start;
    justify-content: flex-start;
	
    position: absolute;
    top: 0;
    width: 0;
    background: $color-white-off;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;
    height: 100vh;
	left: 0;
	box-shadow: $box-shadow inset;
    
    &.is-active {
		width: 300px;
    }
}

.Site-contentWrapper {
    position: relative;
    width: 100%;
	transition: all 0.3s ease-in-out;
	margin-left: 0;
	overflow: hidden;
    
    &.is-active {
        margin-left: 300px;

        .Header {
            left: 300px;
        }
    }
}

.Site-openMenu {
    display: none;

    &.is-active {
        display: block;
    }
}

.Site-closeMenu {
    display: none;
    
    &.is-active {
        display: block;
    }
}

