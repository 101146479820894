.n2-ss-slider .n2-ss-layer-row {
    max-width: 75rem !important;
    margin: 0 auto !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;

    @include media-query($breakpoint-m) {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
    }

    @include media-query($breakpoint-xl) {
        padding-left: 2.5em !important;
        padding-right: 2.5em !important;
    }
}
