.Header {
    background: $color-gray;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100%;
    border-bottom: 1px solid $color-gray;
    color: $color-white;
    transition: all 0.3s ease-in-out;
}

.Header-height {
    height: 71px;
    display: block;
}

.Header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.Header-logo {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5em 0 0.5em;
    flex-wrap: wrap;
    font-family: $font-heading;

    @include media-query($breakpoint-xl) {
        order: 0;
    }
}

.Header-language {
    text-transform: lowercase;
    font-size: 0.8em;
    padding: 0.5em 0;

    display: flex;
    align-items: center;

    @include media-query($breakpoint-xl) {
        order: 3;
    }
}

.Header-nav {
    font-size: 1.15em;
    font-family: $font-heading;
    // text-transform: uppercase;

    @include media-query($breakpoint-xl) {
        order: 1;
    }
}

.Header-search {
    @include media-query($breakpoint-xl) {
        max-width: 200px;
        order: 1;
    }
}

