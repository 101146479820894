/*
Theme Name: Hydraunica
Author: Petra Bredewold
Author URI: www.petrabredewold.nl
Description: Custom template for Hydraunica
Version: 1.0.0
Tags: Hydraunica
Text Domain: hydraunica
*/

@import 'settings';
@import 'base/typography';
@import 'base/elements';
@import 'mixins/font-family';
@import 'mixins/media-query';
@import 'mixins/polyfill';
@import 'components/article';
@import 'components/background';
@import 'components/breadcrumbs';
@import 'components/button';
@import 'components/card';
@import 'components/container';
@import 'components/footer';
@import 'components/form';
@import 'components/flexembed';
@import 'components/grid';
@import 'components/header';
@import 'components/hero';
@import 'components/icon';
@import 'components/inlineform';
@import 'components/list';
@import 'components/media';
@import 'components/nav';
@import 'components/page';
@import 'components/paging';
@import 'components/panel';
@import 'components/search';
@import 'components/sidebar';
@import 'components/site';
@import 'components/slider';
@import 'components/teasercustom';
@import 'utilities/border';
@import 'utilities/color';
@import 'utilities/display';
@import 'utilities/flex';
@import 'utilities/image';
@import 'utilities/link';
@import 'utilities/size';
@import 'utilities/space';
@import 'utilities/text';
@import 'vendor/normalize';
@import 'vendor/smartslider';
@import 'vendor/woocommerce';
@import 'vendor/wp';