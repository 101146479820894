
// On mobile there is a pure CSS dropdown menu when you click on the label/hamburger

.Nav {
    display: flex;
    align-items: center;
}

.Nav-menu .Nav-wrapper {
    top: -60em;
    // right: -60em;
}

.Nav-wrapper {
    top: -60em;
    position: absolute;
    // right: -60em;
    left: 0;
    transition: all .3s cubic-bezier(.17,.04,.03,.94);
    width: 100%;
    z-index: 3;
    background: $color-white;
    padding: 5em 2em 2em;
    box-shadow: $box-shadow;
    width: 100%;
    // display: none;
    z-index: -1;
    
    @include media-query($breakpoint-xxl) {
        // On large screens we want to see the menu anyway
        z-index: 1;
        top: 0;
        right: 0;
        position: relative;
        padding: 0;
        background: transparent;
        box-shadow: none;
        height: inherit;
        max-width: inherit;
        width: inherit;
        // display: block;
    }
}

.Nav-list {
    position: relative;
    z-index: 1;

	// Remove bullets
	list-style: none;
    margin-bottom: 0;
    
	// Remove default space allocated for bullets
    margin-top: 0;
    padding-left: 0;

    @include media-query($breakpoint-xxl) {
        // On larger screens show items next to each other
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
}

.Nav-list-item {
    margin-bottom: 0.5em;

    // &:last-child {
    //     margin-bottom: 0;
    //     background-color: $color-primary;
    //     padding: 0.25em 0.75em;
    //     margin-left: 0.5em;
        
    //     .Nav-link:hover,
    //     .Nav-link:active,
    //     .Nav-link:focus {
    //         border-bottom: 0;
    //         color: $color-white;
    //     }
    // }

    @include media-query($breakpoint-xxl) {
        margin-bottom: 0;
        margin-right: 0.5em;
    }
}

.Nav-link {
    text-decoration: none;
    position: relative;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        border-bottom: 1px solid $color-gray-light;
        color: $color-gray-light;
    }
}

.current-lang a,
.current_page_item a {
    text-decoration: none;
    border-bottom: 1px solid $color-gray-light;
    color: $color-gray-light;  
}

.Nav-toggle {
    display: none;
}

.Nav-login {
    cursor: pointer;
    // To align the icons on the same height
    line-height: 1; 
    // To put the icons on top of the Nav-wrapper
    position: relative;
    z-index: 2;
}

.Nav-label {
    cursor: pointer;
    // To align the icons on the same height
    line-height: 1; 
    // To put the icons on top of the Nav-wrapper
    position: relative;
    z-index: 1;
}

.Nav-closeIcon {
    position: absolute;
    right: 1em;
    top: 1em;
    cursor: pointer;
}

.Nav-close {
    background: 0 0;
    cursor: pointer;
    height: 100vh;
    right: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include media-query($breakpoint-xxl) {
        display: none;
    }
}

// Acties voor de toggle
.Nav-toggle:checked~.Nav-wrapper {
    top: 0;
    // display: block;
}

.Nav-toggle:checked~.Nav-label>.Icon--menu {
    display: none;
}

.Nav-toggle:checked~.Nav-label>.Icon--close {
    display: block;
}

.lang-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .lang-item {
        margin-right: 0.5em;
    }
    
    .lang-link {
        text-decoration: none;
    }

}

.Site-contentNav .Nav-list {
    padding: 5em 2em 2em 2em;
    width: 100%;
}

