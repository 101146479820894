.Form {
	width: 100%;
	margin: 0 auto;
}

.Form-field {
	margin-bottom: 1em;
	position: relative;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 0 !important;
}

.Form-label {
	text-align: left;
	font-size: 0.85rem;
	opacity: 0.5;
	width: 100%;
}

.Form-select,
.Form-datepicker,
.Form-textarea,
.Form-input {
	border: 0;
	// border-radius: $border-radius;
	font-size: 0.95em;
	color: $color-gray-dark;
	width: 100%;
	padding: 0.5em 0.75em;
}

.Form-input {
	
	&::placeholder {
		color: $color-gray;
	}
}

.Form-select {
	padding: 0.4em 1.5em 0.4em 0.5em;
	position: relative;
	cursor: pointer;
	background: white;
	height: 2.5em;
}

// Datepicker
.Form-datepicker {
	height: 2.5em;
}
[type="date"] {
	background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
	display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
	opacity: 0;
}


// Radiobox en checkbox
.Form-checkbox,
.Form-radiobox {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	user-select: none;
	font-size: 0.9em;
}

.Form-checkboxInput,
.Form-radioboxInput {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.Form-checkboxCheck,
.Form-radioboxCheck {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: $color-white;
	box-shadow: $box-shadow inset;
	// border-radius: $border-radius;

	&:after {
		content: "";
		position: absolute;
		display: none;
		left: 9px;
		top: 3px;
		width: 8px;
		height: 15px;
		border: solid white;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}
}

.Form-checkbox:hover .Form-checkboxInput ~ .Form-checkboxCheck,
.Form-radiobox:hover .Form-radioboxInput ~ .Form-radioboxCheck {
	background-color: $color-gray;
	border: 1px solid $color-gray;
}
.Form-checkboxInput:checked ~ .Form-checkboxCheck:after,
.Form-radioboxInput:checked ~ .Form-radioboxCheck:after {
	display: block;
}
.Form-checkboxInput:checked ~ .Form-checkboxCheck,
.Form-radioboxInput:checked ~ .Form-radioboxCheck {
	background-color: $color-primary;
	border: 1px solid $color-primary;
}

.Form-checkbox--fullwidth {
	width: 100%;
	margin-bottom: 1em;
}

.Form-submit {
	text-align: right;
	// border-radius: $border-radius;
}

.wpcf7-form {

	.Form-submit {
		position: relative;
		text-align: right;
		
	}
	.Form-submitButton {
		background-color: $color-primary;
		color: $color-white;
		appearance: none;
	
		&:hover,
		&:active
		&:focus {
			background: $color-primary-dark;
		}
	}

	.ajax-loader {
		display: none !important;
	}
	
	.wpcf7-aborted {
		border:	0;
		background: #942527;
		border-radius: 10px;
		text-align: center;
	}

	.wpcf7-mail-sent-ng {
		border:	0;
		background: #628a38;
		border-radius: 10px;
		text-align: center;
	}
}

// Search
.Form--search {
	width: auto;
	position: relative;
	// min-width: 35em;
	width: 100%;
	font-size: 0.9em;

	.Form-input {
		width: 100%;
		padding: 0.45em 0.5em;
		// Make room for absolute search icon
		padding-right: 2em;
		min-width: 13em;
	}

	.Form-submit {
		position: absolute;
		right: 0em;
		top: 0em;
        cursor: pointer;
        z-index: 2;
		color: $color-gray-dark;
		font-size: 1.3em;
		border: 0; 
		background: transparent;
		appearance: none;
	}
}

// Round
.Form--round {

	.Form-select,
	.Form-datepicker,
	.Form-textarea,
	.Form-input {
		border-radius: $border-radius-round;
	}
}

// Border
.wpcf7-form,
.Form--border {

	.Form-checkboxCheck,
	.Form-radioboxCheck,
	.Form-select,
	.Form-datepicker,
	.Form-textarea,
	.Form-input {
		border: 1px solid $color-gray-light;

		&:active,
		&:hover,
		&:focus {
			outline: 0;
			border-color: $color-gray-dark;
		}
	}
}
