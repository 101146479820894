.Card {
    position: relative;
    text-decoration: none;
    // border-radius: $border-radius;
    overflow: hidden;
    background-color: $color-white;
    margin-bottom: 2em;
    // box-shadow: $box-shadow;
    border: 1px solid $color-gray;
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Card-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.Card-figure {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.5%;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        background: linear-gradient(to bottom, rgba($color-gray, 0) 0%,rgba($color-gray, 0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        z-index: 1;
    }
}

.Card-figureImage {
    min-height: 100%;
	width: 100%;
	height: auto;
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    transition: all .3s ease-in-out;
}

.Card-figureTag {
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 1;

    a {
        background-color: $color-primary;
        color: $color-white;
        padding: 0.15em 0.75em 0.3em;
    }
    
    .woocommerce-loop-product__link {
        display: none;
    }
}

.Card-content {
    padding: 1em 1.25em;
    transition: all 0.3s ease-in-out;
    color: $color-gray-dark;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex-grow: 1;
}

.Card-category {
    .woocommerce-LoopProduct-link {
        display: none;
    }
    a {
        background-color: $color-gray-light;
        border-radius: $border-radius;
        padding: 0.25em 0.5em;
        font-size: 0.8em;
        display: inline-block;
        margin-bottom: 0.25em;
    }
}

.Card-title {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid rgba($color-gray-light, 0.35);
    padding-bottom: 0.8em;
    margin-bottom: 0.5em;
    font-size: 1.25em;
}

.Card-subtitle {
    margin: 0;
    color: $color-gray;
    margin-bottom: 0.5em;
}

.Card-intro {
    margin: 0;
    line-height: 1.5;
    font-size: 0.9em;
    flex-grow: 1;
    margin-top: 0.5em;
}

.Card-meta {
    font-size: 0.7em;
    color: $color-gray-light;
    width: 100%;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.2;
    transition: all 0.3s ease-in-out;

    position: absolute;
    bottom: 1em;
    left: 2em;
    color: $color-white;
    z-index: 1;
}

.Card-button {
    margin-top: 1em;
    display: flex;
	align-items: center;
    justify-content: flex-end;
    font-size: 0.9em;
    text-transform: uppercase;
    font-family: $font-heading;
    color: $color-gray-light;
    border-top: 1px solid rgba($color-gray-light, 0.35);
    padding-top: 0.8em;
    margin-top: 1.25em;

	.Icon {
        width: 0;
        margin-left: 0.2em;
		transition: all ease-in-out .3s;
	}
}

// HOVER 
.Card--hover {
    cursor: pointer;

    
    &.is-active,
    &:active,
    &:focus,
    &:hover {
        
        .Card-button {

            .Icon {
                width: 1em;
            }
        }

        .Card-figureImage {
            transform: translate(-50%, -50%) scale(1.05);
        }
    }
}

.Card--border {
    border: 1px solid $color-gray-light;
}

.Card--border.Card--hover {

    &.is-active,
    &:active,
    &:focus,
    &:hover {
        border: 1px solid $color-gray;
    }
}

.Card--borderPrimary {
    border: 1px solid $color-primary;

    &.is-active,
    &:active,
    &:focus,
    &:hover {
        border: 1px solid $color-primary !important;

        .Card-meta,
        .Button--clean,
        .Icon,    
        .Card-content {
            color: $color-primary;
        }
    }
}

.Card--category {

    .Card-figure {
        padding-bottom: calc(100vh - 110px);
    }
}

.Card--person {
    flex-direction: row;

    .Card-figure {
        min-width: 30%;
        height: inherit;
    }

    .Card-figureImage {
        font-size: 3em;
    }
    
    .Card-subtitle {
        font-size: 0.9em;
    }

    .Card-intro {
        margin-top: 0.5em;
    }
}

.Card--logo {

    .Card-figure {
        padding-bottom: 40%;
        background-color: $color-gray-lighter;
        margin: 0;

        &::after {
            display: none;
        }

        img {
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            transition: all .3s ease-in-out;
            padding: 2em 3em;
        }
    }
}

.Card--icon {
    background-color: transparent;
    box-shadow: none;
    border: 0;

    .Card-figure { 
        // border: 3px solid $color-primary;
        // border-radius: 100%;
        width: 6.7em;
        height: inherit;
        padding-bottom: inherit;
        margin: 1em auto;
        
        &:after {
            display: none;
        }
    }
    
    .Card-figureIcon {
        width: 7em;
        fill: $color-gray-dark;
        height: 6em;
    }

    .Card-content {
        justify-content: flex-start;
        padding: 0;
        text-align: center;
    }

    .Card-title {
        font-family: $font-heading;
        font-size: 1.5em;
    }

    .Card-intro {
        margin-top: 0.5em;
    }
}

.Card--product {
    margin-bottom: 1em;

    .Card-figure {
        padding-bottom: 75%;
    }

    .woocommerce-loop-product__title {
        font-size: 1.5em !important;
    }

    .Card-content {
        justify-content: space-between;
    }

    img,
    .attachment-woocommerce_thumbnail {
        min-height: 100%;
        width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        transition: all .3s ease-in-out;
    }

    a.button {
        margin-top: 1em;
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        font-size: 0.9em !important;
        text-transform: uppercase;
        font-family: $font-heading;
        color: $color-gray-light !important;
        border-top: 1px solid rgba($color-gray-light, 0.35) !important;
        padding: 0.8em 1em 0 0 !important;
        margin-top: 1.25em !important;
        background-color: transparent !important;


        &:after {
            content: '';
            background: url('../hydraunica/assets/images/arrow-right.svg');
            vertical-align: middle;
            transition: all 0.3s ease-in-out;
            margin-left: 1.25em;
            width: 1em;
            height: 1em;
            position: absolute;
            background-size: 1em;
            margin-top: 0;
            opacity: 0.25;
        }
    }
}

.product_tag-winner .Card-figure:before {
    content: '';
    background-image: url('/wp-content/uploads/winnerjpg.jpg');
    background-size: cover;
    z-index: 1;
    min-height: 100%;
	width: 100%;
	height: auto;
	position: absolute;
	top: 32%;
	left: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s ease-in-out;
}