
html {
	font-size: 105.5%; // 18px
}

body {
	line-height: 1.5;
	font-family: $font-default;
	color: $color-gray-dark;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
	line-height: 1.25;
	margin: 0.5em 0;
	font-family: $font-heading;
	color: $color-gray-dark;
}

h1,
.h1 {
	font-size: 2.25rem;
}

h2,
.h2 {
	font-size: 2rem;
}

h3,
.h3 {
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1.5rem;
}
