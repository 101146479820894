.Button {
	text-decoration: none;
	background: $color-primary;
	color: $color-white;
	padding: 0.5em 1em;
	// border-radius: $border-radius;
	display: inline-block;
	border: 0;
	cursor: pointer;
	transition: all .2s ease-in-out;
	text-transform: uppercase;
    font-family: $font-heading;

	&:hover,
	&:active,
	&:focus {
		background: $color-primary-dark;
	}
}

.Button--white {
	background-color: $color-white;
	color: $color-gray-dark;

    &:hover,
	&:active,
	&:focus {
		background-color: $color-white;
	}
}


.Button--round {
    border-radius: $border-radius-round;
}

.Button--withArrow {
	display: flex;
	align-items: center;
	justify-content: center;

	.Icon {
		width: 0;
		transition: all ease-in-out .3s;
	}
	&:hover,
	&:active,
	&:focus {
		.Icon {
			width: 1em;
		}
	}
}

.Button--withIcon {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.Button--center {
	text-align: center;
    align-self: center;
}

.Button--clean {
	background-color: transparent;
	padding: 0;
	color: $color-gray-light;

	&:hover,
	&:active,
	&:focus {
		background: transparent;
		color: $color-gray;
	}
}


.Button--cleanWhite {
	background-color: transparent;
	padding: 0;
	color: rgba($color-white, 0.5);

	&:hover,
	&:active,
	&:focus {
		background: transparent;
		color: rgba($color-white, 0.8);
	}
}

.Button--right {
	margin-left: auto;
	justify-content: flex-end;
}

.Button--left {
	margin-right: auto;
	justify-content: flex-start;
}

.Button--small {
	font-size: 0.85em;
	padding: 0.3em 0.7em;
}

.Button--border {
	border: 1px solid $color-white-off;
	background-color: transparent;
	color: $color-gray-dark;

	&:hover,
	&:active,
	&:focus {
		background-color: rgba($color-white-off, 0.5);
		color: $color-gray-dark;
	}
}

.Button--borderDark {
	border: 1px solid $color-gray-dark;
	background-color: transparent;
	color: $color-gray-dark;

	&:hover,
	&:active,
	&:focus {
		background-color: $color-gray-dark;
		color: $color-white;
	}
}

.Button--onWhite {
	border: 1px solid $color-white;
	background-color: transparent;
	color: $color-white;

	&:hover,
	&:active,
	&:focus {
		background-color: rgba($color-white, 1);
		color: $color-gray-dark;
	}
}

.Button--social {
	border-radius: 6em;
	padding: 0.4em 0.5em;
}

