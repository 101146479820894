$breakpoint-m:			30em; //  480px
$breakpoint-ml:         35em;
$breakpoint-l:			40em; //  640px
$breakpoint-xl:			55em; //  880px
$breakpoint-xxl:		60em; //  960px
$breakpoint-xxxl:		80em; // 1280px

$font-fallback-stack:   'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-heading:          'Dosis', sans-serif, Helvetica, Arial, sans-serif;
$font-default:          'Muli', sans-serif, Helvetica, Arial, sans-serif;

$color-gray-lighter:    #f9f9f9;
$color-gray-light:      #cecece;
$color-gray:            #404040;
$color-gray-dark:       #292929;
$color-black:           #000000;
$color-white:           #FFFFFF;
$color-white-off:       #e9eae4;

$color-primary-light:   #D8D7F9;
$color-primary:         #4D478A;
$color-primary-dark:    #272263;

$border-radius:         0.4em;
$border-radius-round:   6em;

$box-shadow:            0 0 0.5em 0 rgba(0, 0, 0, 0.1);
$box-shadow-inset:      0px 0px 21px -9px inset rgba(0, 0, 0, 0.75);
