.Paging,
.navigation.pagination .nav-links,
.woocommerce-page .woocommerce-pagination > ul {
    display: flex !important;
    justify-content: center;
    padding-top: 2em;
    position: relative;
    border: 0 !important;
}

.woocommerce-page .woocommerce-pagination > ul > li {
    margin-right: 0.5em !important;
    
    &:last-child {
        margin-right: 0 !important;
    }
}

.Paging-item,
.nav-links > .page-numbers,
.woocommerce-page .woocommerce-pagination > ul > li > .page-numbers {
    background-color: $color-gray;
    color: $color-white;
    padding: 0.4em 0.6em 0.4em;
    min-width: 2.5em;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 0.5em;
    line-height: inherit !important;

    &:last-child {
        margin-right: 0;
    }

    &:active,
    &:hover,
    &:focus {
        background-color: $color-gray-dark;
        color: $color-white;
    }
}

.Paging-current,
.nav-links .page-numbers.current,
.woocommerce-page .woocommerce-pagination > ul > li > .page-numbers.current {
    background-color: $color-gray-dark;
    color: $color-white;
}

.screen-reader-text {
    display: none;
}
