.Footer {
    color: $color-white;
    line-height: 2;

    p {
        margin-bottom: 0;
    }

    h1,h2,h3,h4 {
        color: $color-white;
    }

    a {
        text-decoration: none;
        
        &:hover {
            color: rgba($color-white, 1);
            text-decoration: none;
        }
    }

    .List {
        margin-bottom: 2em;
    }
}

.Footer-content {
    color: rgba($color-white, 0.65);
    background-color: $color-gray-dark;
    padding: 4em 0;
    font-size: 0.9em; 
}

.Footer-copy {
    color: rgba($color-white, 0.65);
    background-color: $color-gray;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 0.8em;
    width: 100%;
    

}

.Footer-title {
    border-bottom: 1px solid rgba($color-gray, 0.95);
    padding-bottom: 1em;
    margin-bottom: 1em;
    color: $color-white;
}

