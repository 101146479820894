.wp-block-button {
    text-align: right;
    
    .wp-block-button__link {
        opacity: 0.5;
        text-decoration: none;
        display: inline-block;
        border: 0;
        cursor: pointer;
        transition: all .2s ease-in-out;
        text-transform: uppercase;
        font-family: $font-heading;
        background-color: transparent;
        padding: 0;
        color: $color-gray;
        position: relative;
        margin-right: 1.25em;

        &:after {
            content: '';
            background: url('../hydraunica/assets/images/arrow-right.svg');
            vertical-align: middle;
            transition: all 0.3s ease-in-out;
            margin-left: 0.25em;
            width: 1em;
            height: 1em;
            position: absolute;
            background-size: 1em;
            margin-top: 0.25em;
        }

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            color: $color-gray;
            opacity: 1;
        }
    }

}

// nabootsten grid.scss
.wp-block-columns {
    display: flex;
    flex-wrap: wrap !important;
    margin-left: -2em;

    // Reset margins
    .wp-block-column:not(:first-child),
    .wp-block-column:not(:last-child),
    .wp-block-column {
        margin: 0;
    }

    .wp-block-column {
        // nabootsten grid-cell
        display: flex;
        flex-direction: column;
        padding-left: 2em;
        width: 100%;
    }

    &.has-2-columns {
        .wp-block-column {
            @include media-query($breakpoint-l) {
                flex-basis: percentage(1/2);
            }
        }
    }

    &.has-6-columns {
        .wp-block-column {
            flex-basis: percentage(1/2);
            @include media-query($breakpoint-l) {
                flex-basis: percentage(1/3);
            }
            @include media-query($breakpoint-xxl) {
                flex-basis: percentage(1/6);
            }
        }
    }
}

// wp-media
.wp-block-media-text {
    border: 1px solid $color-gray-light;
    background: $color-white;
    display: flex !important;
    flex-grow: 1;
    align-items: flex-start !important;
    
    .wp-block-media-text__media {
        width: percentage(1/3);
        position: relative;
        height: 100%;
        padding-bottom: 56.5%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
            min-height: 100%;
            width: 100%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            transition: all .3s ease-in-out;
        }
    }

    .wp-block-media-text__content {
        padding: 1em !important;
        width: percentage(2/3);
    }
}