/**
 * Web Font loading logic
 *
 * We're using Font Face Observer to make browser font loading behaviour
 * consistent.
 * When the fonts are loading or they have failed loading, the fallback fonts
 * are shown. When the fonts are loaded, they are shown.
 *
 * These state classes are added to the `html` element.
 */
@mixin font-family($family) {

	.is-fontsLoading,
	.is-fontsFailed {

		& {
			font-family: $font-fallback-stack;
		}
	}

	.is-fontsLoaded & {
		font-family: $family, $font-fallback-stack;
	}
}
