*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	margin-top: 0 !important;
}

body {
	min-height: 100%;
}

img {
	height: auto;
	// Make images fluid width ("responsive") by default
	max-width: 100%;
	// Remove whitespace below images (caused by line-height)
	vertical-align: middle;
}

a {
	color: currentColor;
}

p {
	margin-top: 0;
}
