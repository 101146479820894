.u-linkClean {
	color: inherit;
	text-decoration: none;


	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: currentColor;
		text-decoration: underline;

	}
}

.u-linkCleanHover {
	color: inherit;
	text-decoration: none;
	// opacity: 0.85;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: currentColor;
		text-decoration: none;
		// opacity: 1;
	}
}

.u-linkPrimary {
	color: $color-primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: $color-primary;
		text-decoration: none;
	}
}

.u-linkPrimaryHover {
	color: inherit;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		box-shadow: none;
		color: $color-primary;
		text-decoration: none;
	}
}

.u-linkWithIcon {
	display: flex;
	align-items: flex-start;

	.Icon {
		min-width: 1em;
		margin-top: 0.1em;
	}
}