.woocommerce-breadcrumb,
.Breadcrumbs {
    color: $color-gray-light !important;
    font-size: 0.8em !important;
    margin-left: -1em !important;

    a,
    .breadcrumb_last {
        margin-right: 1em;
        margin-left: 1em;
    }
    
    a {
        color: $color-gray-light !important;
        text-decoration: none;
        
        &:hover {
            color: $color-gray !important;
        }
        
    }

    .breadcrumb_last {
        color: lighten($color-gray-light, 10%);
    }
}

